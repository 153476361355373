import {css} from '@emotion/react'
import {useEffect, useRef} from 'react'
import StartpageLogo from '../../../images/4624/startpage-logo.svg'
import {colors} from '../../../constants'

import linkedinIcon from '../../../images/4624/socialIcons/linkedin.svg'
import twitterIcon from '../../../images/4624/socialIcons/x.svg'
import mastodonIcon from '../../../images/4624/socialIcons/mastodon.svg'
import discordIcon from '../../../images/4624/socialIcons/discord.svg'

const socialLinksData = [
    {
        url: `https://www.linkedin.com/company/startpage.com`,
        icon: linkedinIcon,
        label: `linkedin`,
    },
    {
        url: `https://twitter.com/startpage`,
        icon: twitterIcon,
        label: `twitter`,
    },
    {
        url: `https://mastodon.social/@StartpageSearch`,
        icon: mastodonIcon,
        label: `mastodon`,
    },
    {
        url: `https://discord.com/invite/PKEBCm7bUV`,
        icon: discordIcon,
        label: `discord`,
    },
]

const Footer = () => {
    const startpageLogoLink = useRef(`https://www.startpage.com`)

    useEffect(() => {
        if (typeof window !== `undefined`) {
            startpageLogoLink.current = `https://www.startpage.com${window.location.href.match('/de') ? '/de' : ''}`
        }
    }, [])

    return (
        <div
            className="footer"
            css={css`
                background-color: ${colors.whisper};

                padding: 35px 50px;
                font-size: 13px;
                color: ${colors.midnight};
                width: 100%;

                @media (max-width: 1000px) {
                    flex-direction: column;
                }
            `}
        >
            <div
                className="inner-container"
                css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    max-width: 1150px;
                    margin: 0 auto;
                `}
            >
                <a
                    href={startpageLogoLink.current}
                    aria-label="home"
                    rel="noopener noreferrer"
                    css={css`
                        display: inherit;
                    `}
                >
                    <StartpageLogo />
                </a>
                <div
                    className="social-icons"
                    css={css`
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        svg {
                            width: 40px;
                            height: 40px;
                            margin-right: 0.5rem;
                        }
                    `}
                >
                    {socialLinksData.map((socialItem, i) => {
                        const {url, icon, label} = socialItem
                        return (
                            <a
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label={label}
                                key={i}
                                css={css`
                                    &:hover {
                                        svg circle {
                                            fill: ${colors.black};
                                        }
                                    }
                                `}
                            >
                                {icon()}
                            </a>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Footer
