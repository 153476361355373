/** @jsx jsx */
import {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {jsx, css} from '@emotion/react'

import {isChrome, isEdge, isFirefox} from '../../../util/userAgent'
import {isNewTabPage} from '../../../util/url'
import {
    getChromeExtensionLink,
    firefoxClassicInlineInstallLink,
    edgeExtensionLink,
    chromeNewTabStoreLink,
    firefoxNewTabStoreLink,
    edgeNewTabStoreLink,
} from '../../../util/extensionStoreLinks'
import {logDplWithExtensionParams, EVENTS} from '../../../util/dpl'
import {colors} from '../../../constants'

const buttonStyles = css`
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
    border-radius: 10px;
    display: inline-block;
    transition: background-color 0.3s cubic-bezier(0, 0.4, 0.74, 0.63);
    background-color: ${colors.teal};
    border: none;
    color: ${colors.midnight};
    padding: 1rem 2rem;
    &:hover {
        cursor: pointer;
        background-color: ${colors.tealLight2};
    }
`

const Button = ({text, experiment, onClick, ctaType, overrideStyles, languageCode = 'en'}) => {
    const [btnText, setBtnText] = useState(text)
    const [installLink, setInstallLink] = useState(firefoxClassicInlineInstallLink)

    useEffect(() => {
        const setStoreLink = () => {
            if (isEdge()) {
                if (isNewTabPage()) {
                    setInstallLink(edgeNewTabStoreLink)
                } else {
                    setInstallLink(edgeExtensionLink)
                }
            } else if (isChrome()) {
                if (isNewTabPage()) {
                    setInstallLink(chromeNewTabStoreLink)
                } else {
                    setInstallLink(getChromeExtensionLink(languageCode))
                }
            } else {
                // firefox
                // eslint-disable-next-line no-lonely-if
                if (isNewTabPage()) {
                    setInstallLink(firefoxNewTabStoreLink)
                } else {
                    setInstallLink(firefoxClassicInlineInstallLink)
                }
            }
        }
        if (isEdge()) {
            setBtnText(text.replace(`{}`, `Edge`))
        } else if (isChrome()) {
            setBtnText(text.replace(`{}`, `Chrome`))
        } else {
            setBtnText(text.replace(`{}`, `Firefox`))
        }
        setStoreLink()
    }, [languageCode, setBtnText, setInstallLink, text])

    const onBtnClick = () => {
        if (isFirefox()) {
            const showFirefoxMask = new Event('showFirefoxMask')
            window.dispatchEvent(showFirefoxMask)
        }
        logDplWithExtensionParams(EVENTS.StartpageClick, {
            type: ctaType,
            experiment,
        }).then(() => {
            window.location.href = installLink
        })
    }

    return (
        <button
            className="conversion-button"
            onClick={onClick || onBtnClick}
            aria-label={btnText}
            css={css`
                ${buttonStyles}
                ${overrideStyles}
            `}
        >
            {btnText}
        </button>
    )
}

Button.propTypes = {
    experiment: PropTypes.string,
    onClick: PropTypes.func,
    ctaType: PropTypes.string,
    text: PropTypes.string,
    overrideStyles: PropTypes.string,
}

Button.defaultProps = {
    experiment: `sp-415`,
    ctaType: `cta-1`,
    text: `Add to Firefox`,
    overrideStyles: ``,
}

export default Button
